
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import { apiGet } from "@/api";

import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import CurrencyObjectSelector from "@/components/selector/CurrencyObjectSelector.vue";
import InputWrapper from "@/components/InputWrapper.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "CreateCoinRateForm",
  components: {
    InputWrapper,
    CurrencyObjectSelector,
    DualRingLoader,
  },
  emits: ["save"],
  setup() {
    const route = useRoute();

    // interface FiatCurrencyId {
    //   id: string;
    //   // Add any other properties you need
    // }

    // let fiatcurrencyId: FiatCurrencyId = {
    //   id: "",
    // };

    // Define the selected fiat currency

    const formData = ref({
      // code: null,

      fiatCurrencyid: null,
      coin: {
        id: route.params.coinRateId,
      },
      rate: null,
      minimumValue: null,
      maximumvalue: null,
    });
    const formError = ref({});
    const loading = ref(false);

    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/admin/coin/rate`, {
          coinTypeId: formData.value.coin.id,
          fiatCurrencyid: formData.value.fiatCurrencyid,
          minimumUsdValue: formData.value.minimumValue,
          maximumUsdValue: formData.value.maximumvalue,
          rate: formData.value.rate,
        });
        ShowSuccess("Coin Created Successfully", () => {
          formData.value = {
            fiatCurrencyid: null,
            coin: {
              id: route.params.coinRateId,
            },
            rate: null,
            minimumValue: null,
            maximumvalue: null,
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create coin: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    return {
      Submit,

      formData,
      formError,
      loading,
    };
  },
});
