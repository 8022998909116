<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form class @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="selector" class="form-label">Select Currency</label>
                <Suspense>
                  <template #default>
                    <InputWrapper :errors="formError.fiatCurrencyid">
                      <CurrencyObjectSelector
                        id="selector"
                        v-model="formData.fiatCurrencyid"
                        required
                      />
                    </InputWrapper>
                  </template>
                  <template #fallback>Loading currencies...</template>
                </Suspense>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="rate" class="form-label">Rate</label>
                <InputWrapper :errors="formError.rate">
                  <input
                    v-model.number="formData.rate"
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    id="rate"
                    step="any"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="minimum_value" class="form-label">Minimum Value</label>
                <InputWrapper :errors="formError.minimumValue">
                  <input
                    v-model.number="formData.minimumValue"
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    id="minimum_value"
                    step="any"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="maximum_value" class="form-label">Maximum Value</label>
                <InputWrapper :errors="formError.maximumvalue">
                  <input
                    v-model.number="formData.maximumvalue"
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    id="maximum_value"
                    step="any"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Create Coin Rate</button>
            </div>
          </form>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import { apiGet } from "@/api";

import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import CurrencyObjectSelector from "@/components/selector/CurrencyObjectSelector.vue";
import InputWrapper from "@/components/InputWrapper.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "CreateCoinRateForm",
  components: {
    InputWrapper,
    CurrencyObjectSelector,
    DualRingLoader,
  },
  emits: ["save"],
  setup() {
    const route = useRoute();

    // interface FiatCurrencyId {
    //   id: string;
    //   // Add any other properties you need
    // }

    // let fiatcurrencyId: FiatCurrencyId = {
    //   id: "",
    // };

    // Define the selected fiat currency

    const formData = ref({
      // code: null,

      fiatCurrencyid: null,
      coin: {
        id: route.params.coinRateId,
      },
      rate: null,
      minimumValue: null,
      maximumvalue: null,
    });
    const formError = ref({});
    const loading = ref(false);

    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/admin/coin/rate`, {
          coinTypeId: formData.value.coin.id,
          fiatCurrencyid: formData.value.fiatCurrencyid,
          minimumUsdValue: formData.value.minimumValue,
          maximumUsdValue: formData.value.maximumvalue,
          rate: formData.value.rate,
        });
        ShowSuccess("Coin Created Successfully", () => {
          formData.value = {
            fiatCurrencyid: null,
            coin: {
              id: route.params.coinRateId,
            },
            rate: null,
            minimumValue: null,
            maximumvalue: null,
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create coin: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    return {
      Submit,

      formData,
      formError,
      loading,
    };
  },
});
</script>

<style scoped></style>
